import type { UseFetchOptions } from "nuxt/app";
import { defu } from "defu";

/*** 
useOFetchHandler
useFetchHandler
useAsyncDataHandler (Not working - but you can do it by  useAsyncData(..., useOFetchHandler(), ...)

useLoadingHandler
useErrorHandler
***/

interface CustomError extends Error {
    data?: any;
}

interface richErrorResp {
    errDesc: string;
    result: { message: string; placeholders: any };
}

const config = useRuntimeConfig();

export function useOFetchHandler(url: string, options: object = {}) {
    const defaults: object = {
        baseURL: config.public.baseURL,
    };

    // for nice deep defaults, please use unjs/defu
    // Recap: console.log(defu({ 'a': { 'b': 2 } }, { 'a': { 'b': 1, 'c': 3 } }))
    // => { a: { b: 2, c: 3 } }
    const params = defu(options, defaults);
    return $fetch(url, params);
}

// Extend the default useFetch
export function useFetchHandlerOLD<T>(url: string, options: UseFetchOptions<T> = {}) {
    // const userAuth = useCookie('token')

    const defaults: UseFetchOptions<T> = {
        baseURL: config.public.baseURL,
        key: url,
        server: false,
        // immediate: false,
        // watch: false,

        onRequestError(_ctx) {
            console.log("useFetchHandler: onRequestError Error");
            console.log(_ctx);
        },

        onResponseError(_ctx) {
            console.log("useFetchHandler: onResponseError Error");
            console.log(_ctx);
            // throw createError(_ctx.response)
            // throw new myBusinessError()
            // throw createError({
            //   _ctx
            //   // ...error.value,
            //   // statusMessage: `Could not fetch data from ${url}`,
            // });
        },

        // set user token if connected
        // headers: userAuth.value
        //   ? { Authorization: `Bearer ${userAuth.value}` }
        //   : {},

        // onResponse(_ctx) {
        // //   _ctx.response._data = new myBusinessResponse(_ctx.response._data)
        // },
    };

    const params = defu(options, defaults);
    return useFetch(url, params);
}

export function useFetchHandler<T>(url: string | (() => string), options: UseFetchOptions<T> = {}, fromFunction: string) {
    function _formatRichErrorMessage(message: string, placeholders: any) {
        let formattedMessage = message;
        for (let key in placeholders) {
            const placeholder = `{${key}}`;
            formattedMessage = formattedMessage.replace(new RegExp(placeholder, "g"), placeholders[key]);
        }
        return formattedMessage;
    }

    options.server = false;
    // console.log(options);

    return useFetch(url, {
        ...options,
        $fetch: useNuxtApp().$customFetch,

        async onRequestError({ request, options, error }) {
            throw createError(useErrorHandler(error, `onRequestError - ${fromFunction}`, false) as string);
        },

        async onResponseError({ request, response, options }) {
            let { respCode, respDesc, result } = response._data;
            if (respCode.toString().length === 4) {
                throw createError(useErrorHandler(response, `${fromFunction}`, false) as string);
            } else {
                const { errDesc, result } = useErrorHandler(response, `${fromFunction}`, true) as richErrorResp;
                const richErrorMessage = _formatRichErrorMessage(result.message, result.placeholders);
                throw createError({ message: errDesc, data: { ...result, richErrorMessage } });
            }
        },
    });
}

// Extend the default useAsyncData composable
// IMPORTANT: This will not work because oFetchOptions will only be initialise once and will not be updated by calling refresh or execute from useAsyncData.
// export function useAsyncDataHandler<T>(key: string, url: string, oFetchOptions: any, useAsyncDataOptions: AsyncDataOptions<T> = {}) {
//   // const userAuth = useCookie('token')

//   const oFetchDefaults: object = {
//     baseURL: config.public.baseURL,
//   }

//   const useAsyncDataDefaults: UseFetchOptions<T> = {
//     server: false,
//   };

//   const newOFetchOptions = defu(oFetchOptions, oFetchDefaults);
//   const newAsyncDataOptions = defu(useAsyncDataOptions, useAsyncDataDefaults);

//   return useAsyncData(
//     key,
//     (): Promise<T> => $fetch(url, newOFetchOptions),
//     newAsyncDataOptions
//   );
// };

// Handles useFetch's status by returning a boolean
export function useLoadingHandler(loadingState: Ref<string>) {
    const isLoading = computed(() => {
        switch (loadingState.value) {
            // IDLE -> Request did not start yet, e.g. because:
            // { immediate: false } and execute was not called ye.
            // { server: false } and status is logged during SSR
            case "idle":
                return true;

            // PENDING -> Requesting data did start
            case "pending":
                return true;

            // SUCCESS -> Requesting data succeeded
            case "success":
                return false;
            // ERROR -> Requesting data failed.
            case "error":
                return false;

            default:
                return false;
        }
    });
    return { isLoading };
}

// Handles useFetch's status by returning a boolean (Expect Idle will return false)
export function useFormLoadingHandler(loadingState: Ref<string>) {
    const isLoading = computed(() => {
        switch (loadingState.value) {
            // IDLE -> Request did not start yet, e.g. because:
            // { immediate: false } and execute was not called ye.
            // { server: false } and status is logged during SSR
            case "idle":
                return false;

            // PENDING -> Requesting data did start
            case "pending":
                return true;

            // SUCCESS -> Requesting data succeeded
            case "success":
                return false;
            // ERROR -> Requesting data failed.
            case "error":
                return false;

            default:
                return false;
        }
    });
    return { isLoading };
}

// Handles any error from the API by providing one error message at a time
// FetchResponse<any> & FetchResponse<ResponseType>
export function useErrorHandler(error: any, errFrom: string, isRichError: boolean = false): string | richErrorResp {
    console.log("------------------------------------------------------");
    try {
        let errorData: any = error._data;
        let errDesc: string | richErrorResp = "";

        if (error) {
            console.error(errFrom || "NO ERROR KEY");
            if (errorData) {
                if (errorData.respCode) {
                    let { respCode, respDesc, result } = errorData;
                    console.error("1 - ", respCode, respDesc);
                    console.error(result);
                    if (!isRichError) {
                        // 4 characters respCode - message from errDesc will suffice
                        errDesc = respDesc;
                    } else {
                        // 5 characters respCode - message from errResult and has to be constructed
                        errDesc = {
                            errDesc: respDesc,
                            result: result,
                        };
                    }
                } else if (errorData.errors) {
                    // Error response from server - Unexpected errors
                    console.error("2", errorData.errors);
                    errDesc = errorData.errors[0];
                } else if (error.message) {
                    // Error response internally
                    console.error("3", error.message);
                    errDesc = error.message;
                } else {
                    // Something else
                    console.error("4", errorData);
                    errDesc = "Something went wrong. Please try again.";
                }
            } else if (error.statusText) {
                console.error("5", error);
                errDesc = `${error.statusText} (${error.status}) - ${error.url}`;
            } else {
                // Something else or onRequestError
                console.error("6", error);
                errDesc = "Something went wrong. Please try again.";
            }
        }
        return errDesc;
    } catch (error) {
        console.error("x", error);
        return "";
    }
}

export function useErrorMessageExtractor(error: any): string {
    console.log(error);

    if (error.data) {
        if (error.data.richErrorMessage) {
            return error.data.richErrorMessage;
        }
    }

    if (error.message) {
        return error.message;
    }

    return "Something went wrong. Please try again.";
}

// Handles any error from the API by providing one error message at a time
// export function useErrorHandler(error: CustomError, errFrom: string) {
//     try {
//         let errorMessages: string = "";

//         if (error) {
//             console.error(errFrom, ":");
//             console.error(error);

//             if (error.data) {
//                 if (error.data.error) {
//                     // Error response from server - Unexpected errors
//                     errorMessages = error.data.error;
//                     console.error("1", error.data.error);
//                 } else if (error.data.errors) {
//                     // Error response from server - Expected errors
//                     errorMessages = error.data.errors[0];
//                     console.error("2", error.data.errors);
//                 } else {
//                     // Something else
//                     errorMessages = "Something went wrong. Please try again.";
//                     console.error("3", "Something went wrong. Please try again.");
//                 }
//             } else if (error.message) {
//                 // Error response internally
//                 errorMessages = error.message;
//                 console.error("4", error.message);
//             } else {
//                 // Console.error above has already been logged
//                 if (Array.isArray(error)) {
//                     errorMessages = error[0];
//                 } else {
//                     errorMessages = error.toString();
//                 }
//             }
//         }
//         return errorMessages;
//     } catch (error) {
//         console.error("x", error);
//         return "";
//     }
// }
